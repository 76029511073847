<template>
  <v-scale-transition>
    <v-btn
      v-if="$vuetify.breakpoint.lgAndUp"
      color="primary"
      @click.stop="$emit('click')"
    >
      <v-icon left>
        {{ icon }}
      </v-icon>
      {{ label }}
    </v-btn>
    <v-btn
      v-else
      color="primary"
      bottom
      dark
      fab
      fixed
      right
      @click.stop="$emit('click')"
    >
      <v-icon>
        {{ icon }}
      </v-icon>
    </v-btn>
  </v-scale-transition>
</template>

<script>
export default {
  name: 'BasePrimaryActionButton',

  props: {
    icon: {
      type: String,
      default: 'mdi-plus',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped>

</style>
