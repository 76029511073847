<template>
  <v-data-table
    :expanded="expandedItem.id ? [expandedItem] : []"
    :headers="tableMixin_displayedHeaders"
    :items="displayedItems"
    :mobile-breakpoint="0"
    :loading="loading"
    :class="{ 'content-loading': loading }"
    :footer-props="{
      'items-per-page-options': [pagination.per_page],
    }"
    :page="pagination.current_page"
    :server-items-length="pagination.total"
    :item-class="() => 'orders-table-row'"
    class="overflow-auto orders-table"
    scrollable
    disable-sort
    @click:row="onRowClick"
    @update:page="tableMixin_changePage"
  >
    <template v-slot:item.is_installation_ordered="{ item }">
      {{ booleanToInterjection[item.is_installation_ordered].text }}
    </template>

    <template v-slot:item.price="{ item }">
      {{ getPrice(item) }}
    </template>

    <template v-slot:item.payments="{ item }">
      <div class="d-flex">
        <v-chip
          :color="paymentItems[item.payment_received].color"
          :disabled="item.disabled"
          class="text-caption persist-opacity mr-1"
          label
          small
          @click.stop="
            $emit('select:payment', item, paymentItems[item.payment_received], 'payment_received')
          "
        >
          {{ paymentItems[item.payment_received].label }}
        </v-chip>

        <v-chip
          :color="paymentItems[item.payment_received_2].color"
          :disabled="item.disabled"
          class="text-caption persist-opacity"
          label
          small
          @click.stop="
            $emit(
              'select:payment',
              item,
              paymentItems[item.payment_received_2],
              'payment_received_2',
            )
          "
        >
          {{ paymentItems[item.payment_received_2].label }}
        </v-chip>
      </div>
    </template>

    <template v-slot:header.parts>
      <v-icon small>mdi-clock-time-nine-outline</v-icon>
      {{ $t('ordered') }} / <v-icon small>mdi-checkbox-marked-outline</v-icon>
      {{ $t('received_parts') }}
    </template>

    <template v-slot:item.parts="{ item }">
      <div class="d-flex">
        <v-menu :min-width="$isExplorer() ? '128' : ''">
          <template v-slot:activator="{ on }">
            <v-chip
              v-on="on"
              :color="ordersPartsConfig[item.locks_ordered].color"
              :disabled="item.disabled"
              class="text-caption persist-opacity mr-1"
              label
              small
            >
              <v-icon small class="mr-1">{{ ordersPartsConfig[item.locks_ordered].icon }}</v-icon>
              {{ $t('locks') }}
            </v-chip>
          </template>
          <v-list dense>
            <v-list-item
              v-for="(
                partsItem, index
              ) in availableOrderPartOptions.available_statuses_for_external_services"
              :key="index"
              @click="$emit('select:parts', item, partsItem, 'locks_ordered')"
            >
              <v-list-item-title class="d-flex align-center">
                <v-icon class="mr-1">{{ ordersPartsConfig[partsItem.id].icon }}</v-icon>
                <span>{{ partsItem.title }}</span>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu :min-width="$isExplorer() ? '128' : ''">
          <template v-slot:activator="{ on }">
            <v-chip
              v-on="on"
              :color="ordersPartsConfig[item.glass_ordered].color"
              :disabled="item.disabled"
              class="text-caption persist-opacity mr-1"
              label
              small
            >
              <v-icon small class="mr-1">{{ ordersPartsConfig[item.glass_ordered].icon }}</v-icon>
              {{ $t('glass_packets') }}
            </v-chip>
          </template>
          <v-list dense>
            <v-list-item
              v-for="(
                partsItem, index
              ) in availableOrderPartOptions.available_statuses_for_external_services"
              :key="index"
              @click="$emit('select:parts', item, partsItem, 'glass_ordered')"
            >
              <v-list-item-title class="d-flex align-center">
                <v-icon class="mr-1">{{ ordersPartsConfig[partsItem.id].icon }}</v-icon>
                <span>{{ partsItem.title }}</span>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu :min-width="$isExplorer() ? '128' : ''">
          <template v-slot:activator="{ on }">
            <v-chip
              v-on="on"
              :color="ordersPartsConfig[item.veneers_ordered].color"
              :disabled="item.disabled"
              class="text-caption persist-opacity"
              label
              small
            >
              <v-icon small class="mr-1">{{ ordersPartsConfig[item.veneers_ordered].icon }}</v-icon>
              {{ $t('veneers') }}
            </v-chip>
          </template>
          <v-list dense>
            <v-list-item
              v-for="(
                partsItem, index
              ) in availableOrderPartOptions.available_statuses_for_external_services"
              :key="index"
              @click="$emit('select:parts', item, partsItem, 'veneers_ordered')"
            >
              <v-list-item-title class="d-flex align-center">
                <v-icon class="mr-1">{{ ordersPartsConfig[partsItem.id].icon }}</v-icon>
                <span>{{ partsItem.title }}</span>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu :min-width="$isExplorer() ? '128' : ''">
          <template v-slot:activator="{ on }">
            <v-chip
              v-on="on"
              :color="ordersPartsConfig[item.paint_ordered].color"
              :disabled="item.disabled"
              class="text-caption persist-opacity ml-1"
              label
              small
            >
              <v-icon small class="mr-1">{{ ordersPartsConfig[item.paint_ordered].icon }}</v-icon>
              {{ $t('paint') }}
            </v-chip>
          </template>
          <v-list dense>
            <v-list-item
              v-for="(
                partsItem, index
              ) in availableOrderPartOptions.available_statuses_for_external_services"
              :key="index"
              @click="$emit('select:parts', item, partsItem, 'paint_ordered')"
            >
              <v-list-item-title class="d-flex align-center">
                <v-icon class="mr-1">{{ ordersPartsConfig[partsItem.id].icon }}</v-icon>
                <span>{{ partsItem.title }}</span>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </template>

    <template v-slot:item.actions="{ item }">
      <BaseActionMenu
        :actions="getRowActions(item)"
        :item="item"
        :loading="$store.getters.loading[`delete:api/orders/${item.id}`]"
      />
    </template>

    <template v-slot:expanded-item="{ item }">
      <BaseExpandedTableRow
        :colspan="tableMixin_displayedHeaders.length"
        :headers="tableMixin_hiddenHeaders"
        :item="item"
        class="pb-3"
      >
        <template v-slot:item.is_installation_ordered>
          {{ booleanToInterjection[item.is_installation_ordered].text }}
        </template>

        <template v-slot:item.price>
          {{ getPrice(item) }}
        </template>

        <template v-slot:item.payments>
          <div class="d-flex">
            <v-chip
              :color="paymentItems[item.payment_received].color"
              :disabled="item.disabled"
              class="text-caption persist-opacity mr-1"
              label
              small
              @click.stop="
                $emit(
                  'select:payment',
                  item,
                  paymentItems[item.payment_received],
                  'payment_received',
                )
              "
            >
              {{ paymentItems[item.payment_received].label }}
            </v-chip>

            <v-chip
              :color="paymentItems[item.payment_received_2].color"
              :disabled="item.disabled"
              class="text-caption persist-opacity"
              label
              small
              @click.stop="
                $emit(
                  'select:payment',
                  item,
                  paymentItems[item.payment_received_2],
                  'payment_received_2',
                )
              "
            >
              {{ paymentItems[item.payment_received_2].label }}
            </v-chip>
          </div>
        </template>

        <template v-slot:header.parts>
          <v-icon small>mdi-clock-time-nine-outline</v-icon>
          {{ $t('ordered') }} / <v-icon small>mdi-checkbox-marked-outline</v-icon>
          {{ $t('received_parts') }}
        </template>

        <template v-slot:item.parts>
          <div class="d-flex flex-column align-baseline">
            <v-menu :min-width="$isExplorer() ? '128' : ''">
              <template v-slot:activator="{ on }">
                <v-chip
                  v-on="on"
                  :color="ordersPartsConfig[item.locks_ordered].color"
                  :disabled="item.disabled"
                  class="text-caption persist-opacity mb-1"
                  label
                  small
                >
                  <v-icon small class="mr-1">{{
                    ordersPartsConfig[item.locks_ordered].icon
                  }}</v-icon>
                  {{ $t('locks') }}
                </v-chip>
              </template>
              <v-list dense>
                <v-list-item
                  v-for="(
                    partsItem, index
                  ) in availableOrderPartOptions.available_statuses_for_external_services"
                  :key="index"
                  @click="$emit('select:parts', item, partsItem, 'locks_ordered')"
                >
                  <v-list-item-title class="d-flex align-center">
                    <v-icon class="mr-1">{{ ordersPartsConfig[partsItem.id].icon }}</v-icon>
                    <span>{{ partsItem.title }}</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-menu :min-width="$isExplorer() ? '128' : ''">
              <template v-slot:activator="{ on }">
                <v-chip
                  v-on="on"
                  :color="ordersPartsConfig[item.glass_ordered].color"
                  :disabled="item.disabled"
                  class="text-caption persist-opacity mb-1"
                  label
                  small
                >
                  <v-icon small class="mr-1">{{
                    ordersPartsConfig[item.glass_ordered].icon
                  }}</v-icon>
                  {{ $t('glass_packets') }}
                </v-chip>
              </template>
              <v-list dense>
                <v-list-item
                  v-for="(
                    partsItem, index
                  ) in availableOrderPartOptions.available_statuses_for_external_services"
                  :key="index"
                  @click="$emit('select:parts', item, partsItem, 'glass_ordered')"
                >
                  <v-list-item-title class="d-flex align-center">
                    <v-icon class="mr-1">{{ ordersPartsConfig[partsItem.id].icon }}</v-icon>
                    <span>{{ partsItem.title }}</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-menu :min-width="$isExplorer() ? '128' : ''">
              <template v-slot:activator="{ on }">
                <v-chip
                  v-on="on"
                  :color="ordersPartsConfig[item.veneers_ordered].color"
                  :disabled="item.disabled"
                  class="text-caption persist-opacity mb-1"
                  label
                  small
                >
                  <v-icon small class="mr-1">{{
                    ordersPartsConfig[item.veneers_ordered].icon
                  }}</v-icon>
                  {{ $t('veneers') }}
                </v-chip>
              </template>
              <v-list dense>
                <v-list-item
                  v-for="(
                    partsItem, index
                  ) in availableOrderPartOptions.available_statuses_for_external_services"
                  :key="index"
                  @click="$emit('select:parts', item, partsItem, 'veneers_ordered')"
                >
                  <v-list-item-title class="d-flex align-center">
                    <v-icon class="mr-1">{{ ordersPartsConfig[partsItem.id].icon }}</v-icon>
                    <span>{{ partsItem.title }}</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-menu :min-width="$isExplorer() ? '128' : ''">
              <template v-slot:activator="{ on }">
                <v-chip
                  v-on="on"
                  :color="ordersPartsConfig[item.paint_ordered].color"
                  :disabled="item.disabled"
                  class="text-caption persist-opacity ml-1"
                  label
                  small
                >
                  <v-icon small class="mr-1">{{
                    ordersPartsConfig[item.paint_ordered].icon
                  }}</v-icon>
                  {{ $t('paint') }}
                </v-chip>
              </template>
              <v-list dense>
                <v-list-item
                  v-for="(
                    partsItem, index
                  ) in availableOrderPartOptions.available_statuses_for_external_services"
                  :key="index"
                  @click="$emit('select:parts', item, partsItem, 'paint_ordered')"
                >
                  <v-list-item-title class="d-flex align-center">
                    <v-icon class="mr-1">{{ ordersPartsConfig[partsItem.id].icon }}</v-icon>
                    <span>{{ partsItem.title }}</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>

        <template v-slot:append>
          <v-spacer />
          <v-btn class="mb-4" color="primary" small @click="onOrderPartCreate(item)">
            <v-icon left>mdi-plus</v-icon>
            {{ $t('add_order_part') }}
          </v-btn>
          <OrderPartsTable
            :items="orderParts"
            :loading="$store.getters.loading['get:api/order-parts']"
            :selectedItems="selectedItems"
            @edit="
              $router.push({
                name: 'editOrderPart',
                params: { id: $event.id },
              })
            "
            @delete="deleteOrderPart"
            @select:status="onSelectPaymentOrPart"
            @select:payment="onSelectPaymentOrPart"
            @select:parts="onSelectPaymentOrPart"
            @generate:manufacturing="onGenerateManufacturingDocument"
            @copy:order-part="onOrderPartCopy"
            @input="getSelectedItems"
          />
        </template>
      </BaseExpandedTableRow>
    </template>
  </v-data-table>
</template>

<script>
import tableMixin from '@/mixins/table-mixin';
import BaseExpandedTableRow from '@/components/base/BaseExpandedTableRow';
import BaseActionMenu from '@/components/base/BaseActionMenu';
import OrderPartsTable from '@/components/OrderPartsTable';
import { mapActions, mapMutations, mapState } from 'vuex';
import orderPartsService from '@/api/order-parts-service';
import { downloadFile } from '@/util/files';
import clone from 'just-clone';

export default {
  name: 'OrdersTable',

  components: { OrderPartsTable, BaseActionMenu, BaseExpandedTableRow },

  mixins: [tableMixin],

  props: {
    items: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    expandedItem: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      selectedItems: [],
    };
  },

  computed: {
    ...mapState('orderParts', ['orderParts', 'availableOrderPartOptions', 'creatingOrderPart']),

    booleanToInterjection() {
      return {
        false: {
          text: this.$t('no'),
        },
        true: {
          text: this.$t('yes'),
        },
      };
    },

    ordersPartsConfig() {
      return {
        0: {
          id: 0,
          color: '',
          icon: 'mdi-checkbox-blank-outline',
        },
        1: {
          id: 1,
          color: '#FFD54F',
          icon: 'mdi-clock-time-nine-outline',
        },
        2: {
          id: 2,
          color: '#81D4FA',
          icon: 'mdi-checkbox-marked-outline',
        },
      };
    },

    paymentItems() {
      return {
        false: {
          value: false,
          label: this.$t('no'),
          color: '#FF8A80',
        },
        true: {
          value: true,
          label: this.$t('yes'),
          color: '#9FA8DA',
        },
      };
    },

    headers() {
      return [
        {
          text: this.$t('internal_no'),
          value: 'internal_no',
          hidden: 'xsOnly',
        },
        {
          text: this.$t('date'),
          value: 'date',
          hidden: 'lgAndDown',
        },
        {
          text: this.$t('deadline_at'),
          value: 'deadline_at',
          hidden: 'lgAndDown',
        },
        {
          text: this.$t('price'),
          value: 'price',
          hidden: 'xsOnly',
        },
        {
          text: this.$t('buyer_name'),
          value: 'buyer_name',
        },
        {
          text: this.$t('installation_address'),
          value: 'installation_address',
          hidden: 'xsOnly',
        },
        {
          text: this.$t('buyer_phone'),
          value: 'buyer_phone',
          hidden: 'lgAndDown',
        },
        {
          text: this.$t('is_installation_ordered'),
          value: 'is_installation_ordered',
          hidden: 'lgAndDown',
        },
        {
          text: this.$t('payments'),
          value: 'payments',
          hidden: 'mdAndDown',
        },
        {
          value: 'parts',
          hidden: 'mdAndDown',
        },
        {
          text: this.$t('comments'),
          value: 'comments',
          hidden: 'lgAndDown',
        },
        {
          value: 'actions',
          align: 'end',
        },
      ];
    },

    displayedItems() {
      if (!this.items) {
        return [];
      }
      return this.items.map((r) => ({
        ...r,
      }));
    },
  },

  methods: {
    ...mapActions('orderParts', ['deleteOrderPart', 'updatePaymentsAndParts']),
    ...mapMutations('orderParts', [
      'SET_NEW_ORDER_PART',
      'SET_CREATING_ORDER_PART',
      'RESET_NEW_ORDER_PART',
    ]),

    getRowActions() {
      const actions = [
        {
          callback: (p) => this.$emit('edit', p),
          label: this.$t('edit'),
          icon: 'mdi-pencil',
        },
        {
          callback: (p) => this.$emit('delete', p),
          label: this.$t('delete'),
          icon: 'mdi-delete',
        },
        {
          callback: (p) => this.$emit('download:order', p),
          label: this.$t('download_order_sheet'),
          icon: 'mdi-file-download',
        },
      ];
      return actions;
    },

    getSelectedItems(event) {
      this.selectedItems = event;
    },

    onGenerateManufacturingDocument(event) {
      const ids = [];
      this.selectedItems.forEach((item) => {
        ids.push(item.id);
      });
      if (this.isItemSelected(event) || !ids.length) {
        return orderPartsService
          .generateManufacturingDocuments(this.selectedItems.length ? { ids } : { ids: [event.id] })
          .then((res) => {
            const name = res.headers['content-disposition'].slice(
              res.headers['content-disposition'].indexOf('filename="') + 22,
            );
            downloadFile(res.data, name);
          });
      }
      return Promise.resolve();
    },

    onSelectPaymentOrPart(item, paymentOrPartItem, attr) {
      const selectedIds = [];
      this.selectedItems.forEach((selectedItem) => {
        selectedIds.push(selectedItem.id);
      });
      const payload = {
        ids: selectedIds.length ? selectedIds : [item.id],
        attribute: attr,
        value:
          attr === 'payment_received' || attr === 'payment_received_2'
            ? !paymentOrPartItem.value
            : paymentOrPartItem.id,
      };
      if (this.isItemSelected(item) || !selectedIds.length) {
        this.updatePaymentsAndParts(payload);
      }
    },

    isItemSelected(item) {
      for (let i = 0; i < this.selectedItems.length; i++) {
        if (this.selectedItems[i].id === item.id) {
          return true;
        }
      }
      return false;
    },

    onOrderPartCopy(orderPart) {
      const copiedOrderPart = clone(orderPart);
      copiedOrderPart.jamb_type = null;
      copiedOrderPart.location_in_building = null;
      copiedOrderPart.door_lock = null;
      this.SET_CREATING_ORDER_PART(false);
      this.SET_NEW_ORDER_PART(copiedOrderPart);
      this.$router.push({ name: 'createOrderPart', query: { order_id: copiedOrderPart.order_id } });
    },

    onOrderPartCreate(item) {
      if (!this.creatingOrderPart) {
        this.RESET_NEW_ORDER_PART();
      }
      this.SET_CREATING_ORDER_PART(true);
      this.$router.push({ name: 'createOrderPart', query: { order_id: item.id } }).catch(() => {});
    },

    onRowClick(order) {
      //   if (!this.tableMixin_firstExpandedRowKey) {
      //     // If none of the rows is expanded
      //     this.$store.commit('orderParts/SET_ORDER_PARTS', order.parts);
      //   } else if (this.tableMixin_firstExpandedRowKey !== order.id) {
      //     // If one of the row is expanded and user clicked on another one
      //     this.$store.commit('orders/UPDATE_ORDER', {
      //       ...this.tableMixin_expandedRows[0],
      //       parts: this.orderParts,
      //     });
      //     this.$store.commit('orderParts/SET_ORDER_PARTS', order.parts);
      //   } else {
      //     // If user closed all rows
      //     this.$store.commit('orders/UPDATE_ORDER', {
      //       ...this.tableMixin_expandedRows[0],
      //       parts: this.orderParts,
      //     });
      //   }
      //   this.tableMixin_expandRow(order);
      // },

      if (this.expandedItem.id !== order.id) {
        // If none of the rows is expanded
        this.$store.commit('orders/SET_EXPANDED_ORDER', order);
        this.$store.commit('orderParts/SET_ORDER_PARTS', order.parts);
      } else {
        this.$store.commit('orders/SET_EXPANDED_ORDER', {});
      }
    },

    getPrice(order) {
      if (order.final_overridden_price) {
        return order.final_overridden_price;
      }
      let calculatedPrice = 0;
      for (let i = 0; i < order.parts.length; i++) {
        calculatedPrice += order.parts[i].calculated_price;
      }
      return calculatedPrice.toFixed(2);
    },
  },
};
</script>

<style scoped></style>
