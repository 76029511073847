<template>
  <v-data-table
    :expanded="tableMixin_expandedRows"
    :headers="tableMixin_displayedHeaders"
    :items="displayedItems"
    :mobile-breakpoint="0"
    :loading="loading"
    :class="{ 'content-loading': loading }"
    show-select
    class="overflow-auto"
    scrollable
    disable-sort
    hide-default-footer
    disable-pagination
    @click:row="tableMixin_onRowClick"
    @update:page="tableMixin_changePage"
    @input="$emit('input', $event)"
  >
    <template v-slot:header.parts>
      <v-icon small>mdi-clock-time-nine-outline</v-icon>
      {{ $t('ordered') }} / <v-icon small>mdi-checkbox-marked-outline</v-icon>
      {{ $t('received_parts') }}
    </template>

    <template v-slot:item.calculated_price="{ item }"> {{ item.calculated_price }}&euro; </template>

    <template v-slot:item.status="{ item }">
      <v-menu :min-width="$isExplorer() ? '178' : ''">
        <template v-slot:activator="{ on }">
          <v-chip
            v-on="on"
            :color="chipColorByStatus[item.status]"
            :disabled="item.disabled"
            class="text-caption persist-opacity"
            label
            small
          >
            {{ orderPartsStatuses[item.status].title }}
          </v-chip>
        </template>
        <v-list dense>
          <v-list-item
            v-for="(status, index) in availableOrderPartOptions.available_statuses"
            :key="index"
            @click="$emit('select:status', item, status, 'status')"
          >
            <v-list-item-title>{{ status.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>

    <template v-slot:item.payments="{ item }">
      <div class="d-flex">
        <v-chip
          :color="paymentItems[item.payment_received].color"
          :disabled="item.disabled"
          class="text-caption persist-opacity mr-1"
          label
          small
          @click.stop="
            $emit('select:payment', item, paymentItems[item.payment_received], 'payment_received')
          "
        >
          {{ paymentItems[item.payment_received].label }}
        </v-chip>

        <v-chip
          :color="paymentItems[item.payment_received_2].color"
          :disabled="item.disabled"
          class="text-caption persist-opacity"
          label
          small
          @click.stop="
            $emit(
              'select:payment',
              item,
              paymentItems[item.payment_received_2],
              'payment_received_2',
            )
          "
        >
          {{ paymentItems[item.payment_received_2].label }}
        </v-chip>
      </div>
    </template>

    <template v-slot:item.parts="{ item }">
      <div class="d-flex">
        <v-menu :min-width="$isExplorer() ? '128' : ''">
          <template v-slot:activator="{ on }">
            <v-chip
              v-on="on"
              :color="orderPartPartsConfig[item.locks_ordered].color"
              :disabled="item.disabled"
              class="text-caption persist-opacity mr-1"
              label
              small
            >
              <v-icon small class="mr-1">{{
                orderPartPartsConfig[item.locks_ordered].icon
              }}</v-icon>
              {{ $t('locks') }}
            </v-chip>
          </template>
          <v-list dense>
            <v-list-item
              v-for="(
                partsItem, index
              ) in availableOrderPartOptions.available_statuses_for_external_services"
              :key="index"
              @click="$emit('select:parts', item, partsItem, 'locks_ordered')"
            >
              <v-list-item-title class="d-flex align-center">
                <v-icon class="mr-1">{{ orderPartPartsConfig[partsItem.id].icon }}</v-icon>
                <span>{{ partsItem.title }}</span>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu v-if="item.glass_main_door" :min-width="$isExplorer() ? '128' : ''">
          <template v-slot:activator="{ on }">
            <v-chip
              v-on="on"
              :color="orderPartPartsConfig[item.glass_ordered].color"
              :disabled="item.disabled"
              class="text-caption persist-opacity mr-1"
              label
              small
            >
              <v-icon small class="mr-1">{{
                orderPartPartsConfig[item.glass_ordered].icon
              }}</v-icon>
              {{ $t('glass_packets') }}
            </v-chip>
          </template>
          <v-list dense>
            <v-list-item
              v-for="(
                partsItem, index
              ) in availableOrderPartOptions.available_statuses_for_external_services"
              :key="index"
              @click="$emit('select:parts', item, partsItem, 'glass_ordered')"
            >
              <v-list-item-title class="d-flex align-center">
                <v-icon class="mr-1">{{ orderPartPartsConfig[partsItem.id].icon }}</v-icon>
                <span>{{ partsItem.title }}</span>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu :min-width="$isExplorer() ? '128' : ''">
          <template v-slot:activator="{ on }">
            <v-chip
              v-on="on"
              :color="orderPartPartsConfig[item.veneers_ordered].color"
              :disabled="item.disabled"
              class="text-caption persist-opacity"
              label
              small
            >
              <v-icon small class="mr-1">{{
                orderPartPartsConfig[item.veneers_ordered].icon
              }}</v-icon>
              {{ $t('veneers') }}
            </v-chip>
          </template>
          <v-list dense>
            <v-list-item
              v-for="(
                partsItem, index
              ) in availableOrderPartOptions.available_statuses_for_external_services"
              :key="index"
              @click="$emit('select:parts', item, partsItem, 'veneers_ordered')"
            >
              <v-list-item-title class="d-flex align-center">
                <v-icon class="mr-1">{{ orderPartPartsConfig[partsItem.id].icon }}</v-icon>
                <span>{{ partsItem.title }}</span>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu :min-width="$isExplorer() ? '128' : ''">
          <template v-slot:activator="{ on }">
            <v-chip
              v-on="on"
              :color="orderPartPartsConfig[item.paint_ordered].color"
              :disabled="item.disabled"
              class="text-caption persist-opacity ml-1"
              label
              small
            >
              <v-icon small class="mr-1">{{
                orderPartPartsConfig[item.paint_ordered].icon
              }}</v-icon>
              {{ $t('paint') }}
            </v-chip>
          </template>
          <v-list dense>
            <v-list-item
              v-for="(
                partsItem, index
              ) in availableOrderPartOptions.available_statuses_for_external_services"
              :key="index"
              @click="$emit('select:parts', item, partsItem, 'paint_ordered')"
            >
              <v-list-item-title class="d-flex align-center">
                <v-icon class="mr-1">{{ orderPartPartsConfig[partsItem.id].icon }}</v-icon>
                <span>{{ partsItem.title }}</span>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </template>

    <template v-slot:item.actions="{ item }">
      <BaseActionMenu
        :actions="getRowActions(item)"
        :item="item"
        :loading="$store.getters.loading[`delete:api/orders/${item.id}`]"
      />
    </template>

    <template v-slot:expanded-item="{ item }">
      <BaseExpandedTableRow
        :colspan="tableMixin_displayedHeaders.length"
        :headers="tableMixin_hiddenHeaders"
        :item="item"
        class="pb-3"
      >
        <template v-slot:header.parts>
          <v-icon small>mdi-clock-time-nine-outline</v-icon>
          {{ $t('ordered') }} / <v-icon small>mdi-checkbox-marked-outline</v-icon>
          {{ $t('received_parts') }}
        </template>

        <template v-slot:item.calculated_price> {{ item.calculated_price }}&euro; </template>

        <template v-slot:item.status>
          <v-menu :min-width="$isExplorer() ? '178' : ''">
            <template v-slot:activator="{ on }">
              <v-chip
                v-on="on"
                :color="chipColorByStatus[item.status]"
                :disabled="item.disabled"
                class="text-caption persist-opacity"
                label
                small
              >
                {{ orderPartsStatuses[item.status].title }}
              </v-chip>
            </template>
            <v-list dense>
              <v-list-item
                v-for="(status, index) in availableOrderPartOptions.available_statuses"
                :key="index"
                @click="$emit('select:status', item, status, 'status')"
              >
                <v-list-item-title>{{ status.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>

        <template v-slot:item.payments>
          <div class="d-flex">
            <v-chip
              :color="paymentItems[item.payment_received].color"
              :disabled="item.disabled"
              class="text-caption persist-opacity mr-1"
              label
              small
              @click.stop="
                $emit(
                  'select:payment',
                  item,
                  paymentItems[item.payment_received],
                  'payment_received',
                )
              "
            >
              {{ paymentItems[item.payment_received].label }}
            </v-chip>

            <v-chip
              :color="paymentItems[item.payment_received_2].color"
              :disabled="item.disabled"
              class="text-caption persist-opacity"
              label
              small
              @click.stop="
                $emit(
                  'select:payment',
                  item,
                  paymentItems[item.payment_received_2],
                  'payment_received_2',
                )
              "
            >
              {{ paymentItems[item.payment_received_2].label }}
            </v-chip>
          </div>
        </template>

        <template v-slot:item.parts>
          <div class="d-flex">
            <v-menu :min-width="$isExplorer() ? '128' : ''">
              <template v-slot:activator="{ on }">
                <v-chip
                  v-on="on"
                  :color="orderPartPartsConfig[item.locks_ordered].color"
                  :disabled="item.disabled"
                  class="text-caption persist-opacity mr-1"
                  label
                  small
                >
                  <v-icon small class="mr-1">{{
                    orderPartPartsConfig[item.locks_ordered].icon
                  }}</v-icon>
                  {{ $t('locks') }}
                </v-chip>
              </template>
              <v-list dense>
                <v-list-item
                  v-for="(
                    partsItem, index
                  ) in availableOrderPartOptions.available_statuses_for_external_services"
                  :key="index"
                  @click="$emit('select:parts', item, partsItem, 'locks_ordered')"
                >
                  <v-list-item-title class="d-flex align-center">
                    <v-icon class="mr-1">{{ orderPartPartsConfig[partsItem.id].icon }}</v-icon>
                    <span>{{ partsItem.title }}</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-menu v-if="item.glass_main_door" :min-width="$isExplorer() ? '128' : ''">
              <template v-slot:activator="{ on }">
                <v-chip
                  v-on="on"
                  :color="orderPartPartsConfig[item.glass_ordered].color"
                  :disabled="item.disabled"
                  class="text-caption persist-opacity mr-1"
                  label
                  small
                >
                  <v-icon small class="mr-1">{{
                    orderPartPartsConfig[item.glass_ordered].icon
                  }}</v-icon>
                  {{ $t('glass_packets') }}
                </v-chip>
              </template>
              <v-list dense>
                <v-list-item
                  v-for="(
                    partsItem, index
                  ) in availableOrderPartOptions.available_statuses_for_external_services"
                  :key="index"
                  @click="$emit('select:parts', item, partsItem, 'glass_ordered')"
                >
                  <v-list-item-title class="d-flex align-center">
                    <v-icon class="mr-1">{{ orderPartPartsConfig[partsItem.id].icon }}</v-icon>
                    <span>{{ partsItem.title }}</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-menu :min-width="$isExplorer() ? '128' : ''">
              <template v-slot:activator="{ on }">
                <v-chip
                  v-on="on"
                  :color="orderPartPartsConfig[item.veneers_ordered].color"
                  :disabled="item.disabled"
                  class="text-caption persist-opacity"
                  label
                  small
                >
                  <v-icon small class="mr-1">{{
                    orderPartPartsConfig[item.veneers_ordered].icon
                  }}</v-icon>
                  {{ $t('veneers') }}
                </v-chip>
              </template>
              <v-list dense>
                <v-list-item
                  v-for="(
                    partsItem, index
                  ) in availableOrderPartOptions.available_statuses_for_external_services"
                  :key="index"
                  @click="$emit('select:parts', item, partsItem, 'veneers_ordered')"
                >
                  <v-list-item-title class="d-flex align-center">
                    <v-icon class="mr-1">{{ orderPartPartsConfig[partsItem.id].icon }}</v-icon>
                    <span>{{ partsItem.title }}</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-menu :min-width="$isExplorer() ? '128' : ''">
              <template v-slot:activator="{ on }">
                <v-chip
                  v-on="on"
                  :color="orderPartPartsConfig[item.paint_ordered].color"
                  :disabled="item.disabled"
                  class="text-caption persist-opacity ml-1"
                  label
                  small
                >
                  <v-icon small class="mr-1">{{
                    orderPartPartsConfig[item.paint_ordered].icon
                  }}</v-icon>
                  {{ $t('paint') }}
                </v-chip>
              </template>
              <v-list dense>
                <v-list-item
                  v-for="(
                    partsItem, index
                  ) in availableOrderPartOptions.available_statuses_for_external_services"
                  :key="index"
                  @click="$emit('select:parts', item, partsItem, 'paint_ordered')"
                >
                  <v-list-item-title class="d-flex align-center">
                    <v-icon class="mr-1">{{ orderPartPartsConfig[partsItem.id].icon }}</v-icon>
                    <span>{{ partsItem.title }}</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
      </BaseExpandedTableRow>
    </template>
  </v-data-table>
</template>

<script>
import tableMixin from '@/mixins/table-mixin';
import BaseActionMenu from '@/components/base/BaseActionMenu';
import { mapGetters, mapState } from 'vuex';
import BaseExpandedTableRow from '@/components/base/BaseExpandedTableRow';

export default {
  name: 'OrderPartsTable',

  components: { BaseExpandedTableRow, BaseActionMenu },

  mixins: [tableMixin],

  props: {
    items: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    selectedItems: {
      type: Array,
      required: true,
    },
  },

  computed: {
    ...mapState('orderParts', ['availableOrderPartOptions']),
    ...mapGetters('orderParts', ['orderPartsStatuses']),

    chipColorByStatus() {
      return {
        saved: '#CCFF90',
        new: '',
        ordered: '#FFF176',
        inprogress: '#81D4FA',
        completed: '#9FA8DA',
        delivered: '#66BB6A',
        canceled: '#FF8A80',
      };
    },

    orderPartPartsConfig() {
      return {
        0: {
          id: 0,
          color: '',
          icon: 'mdi-checkbox-blank-outline',
        },
        1: {
          id: 1,
          color: '#FFD54F',
          icon: 'mdi-clock-time-nine-outline',
        },
        2: {
          id: 2,
          color: '#81D4FA',
          icon: 'mdi-checkbox-marked-outline',
        },
      };
    },

    paymentItems() {
      return {
        false: {
          value: false,
          label: this.$t('no'),
          color: '#FF8A80',
        },
        true: {
          value: true,
          label: this.$t('yes'),
          color: '#9FA8DA',
        },
      };
    },

    headers() {
      return [
        // {
        //   text: this.$t('order_no_external'),
        //   value: 'order_no_external',
        //   hidden: 'xsOnly',
        // },
        {
          text: this.$t('order_no_internal'),
          value: 'order_no_internal',
        },
        {
          text: this.$t('location_in_building'),
          value: 'location_in_building',
          hidden: 'lgAndDown',
        },
        {
          text: this.$t('confirmed_at'),
          value: 'confirmed_at',
          hidden: 'lgAndDown',
        },
        {
          text: this.$t('status'),
          value: 'status',
          hidden: 'xsOnly',
        },
        {
          text: this.$t('calculated_price'),
          value: 'calculated_price',
          hidden: 'mdAndDown',
        },
        // {
        //   text: this.$t('payments'),
        //   value: 'payments',
        //   hidden: 'smAndDown',
        // },
        {
          value: 'parts',
          hidden: 'smAndDown',
        },
        {
          value: 'actions',
          align: 'end',
        },
      ];
    },

    displayedItems() {
      if (!this.items) {
        return [];
      }
      return this.items.map((r) => ({
        ...r,
        disabled: this.isItemDisabled(r),
      }));
    },
  },

  methods: {
    isItemDisabled(item) {
      if (!this.selectedItems.length) {
        return false;
      }
      for (let i = 0; i < this.selectedItems.length; i++) {
        if (this.selectedItems[i].id === item.id) {
          return false;
        }
      }
      return true;
    },

    getRowActions(item) {
      const actions = [
        {
          callback: (p) => this.$emit('edit', p),
          label: this.$t('edit'),
          icon: 'mdi-pencil',
        },
        {
          callback: (p) => this.$emit('generate:manufacturing', p),
          label: this.$t('generate_manufacturing_documents'),
          icon: 'mdi-file-document-multiple-outline',
          disabled: item.disabled,
        },
        {
          callback: (p) => this.$emit('copy:order-part', p),
          label: this.$t('copy_order_part'),
          icon: 'mdi-content-copy',
        },
      ];

      actions.push({
        callback: (p) => this.$emit('delete', p),
        label: this.$t('delete'),
        icon: 'mdi-delete',
      });

      return actions;
    },
  },
};
</script>

<style scoped></style>
