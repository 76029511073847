<template>
  <div class="page-wrapper">
    <div class="d-flex align-center pa-4">
      <h1 class="title">
        {{ $t('orders') }}
      </h1>
      <v-spacer />
      <v-menu offset-y>
        <!--Swap dropdown-->
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            v-bind="attrs"
            v-on="on"
            icon
            :class="$vuetify.breakpoint.mdAndDown ? '' : 'mx-2'"
          >
            <v-icon size="32">mdi-swap-vertical-bold</v-icon>
          </v-btn>
        </template>
        <v-list v-for="item in swapDropdownItems" :key="item.label">
          <v-list-item :class="$isExplorer() ? 'd-block px-4' : 'd-flex'" @click="item.action">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title :class="$isExplorer() ? 'px-2 pt-3' : ''">
              {{ $t(item.label) }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <BasePrimaryActionButton
        :label="$t('create_order')"
        @click="$router.push({ name: 'createOrder' })"
      />
    </div>

    <OrdersFilter
      :filter-params="orderFilterParams"
      :errors="orderValidationErrors"
      :available-options="availableOrderPartOptions"
      @update="$router.push({ name: 'orders', query: { ...$event } }).catch(() => {})"
      @reset="resetOrderFilters"
    />

    <BaseTableLoader :loading="!orders">
      <OrdersTable
        :items="orders"
        :loading="$store.getters.loading['get:api/orders']"
        :pagination="ordersPagination"
        :expanded-item="expandedOrder"
        @edit="$router.push({ name: 'editOrder', params: { id: $event.id } }).catch(() => {})"
        @delete="deleteOrder"
        @update:page="onPageChange"
        @download:order="onOrderSheetDownload"
        @select:payment="onPaymentOrPartStatusChange"
        @select:parts="onPaymentOrPartStatusChange"
      />
    </BaseTableLoader>
    <router-view />
  </div>
</template>

<script>
import BasePrimaryActionButton from '@/components/base/BasePrimaryActionButton';
import BaseTableLoader from '@/components/base/BaseTableLoader';
import OrdersTable from '@/components/OrdersTable';
import { mapActions, mapState } from 'vuex';
import ordersService from '@/api/orders-service';
import { downloadFile } from '@/util/files';
import OrdersFilter from '@/components/OrdersFilter';
import { getDefaultOrderFilterParams } from '@/store/modules/orders-module';

export default {
  name: 'Orders',

  components: { OrdersFilter, BaseTableLoader, BasePrimaryActionButton, OrdersTable },

  created() {
    if (!this.orders) {
      this.fetchOrders(this.transformQueryParams(this.$route.query));
    }

    if (!Object.keys(this.availableOrderPartOptions).length) {
      this.fetchAvailableOptions();
    }
  },

  beforeRouteUpdate(to, from, next) {
    if (
      JSON.stringify(this.orderFilterParams) !== JSON.stringify(to.query) &&
      to.name === 'orders'
    ) {
      this.fetchOrders(this.transformQueryParams(to.query));
    }
    next();
  },

  computed: {
    ...mapState('orders', [
      'orders',
      'ordersPagination',
      'orderValidationErrors',
      'orderFilterParams',
      'expandedOrder',
    ]),
    ...mapState('orderParts', ['availableOrderPartOptions']),

    swapDropdownItems() {
      return [
        {
          label: 'import_orders',
          icon: 'mdi-import',
          action: () => this.$router.push({ name: 'importOrders' }),
        },
        {
          label: 'export_orders',
          icon: 'mdi-export',
          action: () => this.$router.push({ name: 'exportOrders' }),
        },
      ];
    },
  },

  methods: {
    ...mapActions('orders', ['fetchOrders', 'updateOrder', 'deleteOrder']),
    ...mapActions('orderParts', ['fetchAvailableOptions']),

    onPaymentOrPartStatusChange(item, paymentOrPartItem, attr) {
      const order = JSON.parse(JSON.stringify(item));
      order[attr] =
        attr === 'payment_received' || attr === 'payment_received_2'
          ? !paymentOrPartItem.value
          : paymentOrPartItem.id;
      this.updateOrder(order);
    },

    transformQueryParams(query) {
      const transformedQuery = { ...query };
      if (transformedQuery.glass_ordered) {
        transformedQuery.glass_ordered = +transformedQuery.glass_ordered;
      }
      if (transformedQuery.locks_ordered) {
        transformedQuery.locks_ordered = +transformedQuery.locks_ordered;
      }
      if (transformedQuery.per_page) {
        transformedQuery.per_page = +transformedQuery.per_page;
      }
      if (transformedQuery.product_ids && typeof transformedQuery.product_ids !== 'object') {
        transformedQuery.product_ids = [transformedQuery.product_ids];
      }
      if (transformedQuery.product_ids?.length) {
        transformedQuery.product_ids = transformedQuery.product_ids.map((id) => +id);
      }
      if (transformedQuery.statuses && typeof transformedQuery.statuses !== 'object') {
        transformedQuery.statuses = [transformedQuery.statuses];
      }
      return transformedQuery;
    },

    onOrderSheetDownload(order) {
      ordersService.downloadOrderAsPdf(order).then((res) => {
        const name = res.headers['content-disposition'].slice(
          res.headers['content-disposition'].indexOf('filename="') + 10,
          res.headers['content-disposition'].length - 1,
        );
        downloadFile(res.data, name);
      });
    },

    onPageChange(page) {
      const query = { ...this.orderFilterParams, page };
      this.$router.push({ name: 'orders', query });
    },

    resetOrderFilters() {
      this.$router.push({ name: 'orders', query: getDefaultOrderFilterParams() }).catch(() => {});
    },
  },
};
</script>

<style scoped></style>
