<template>
  <v-container fluid class="py-0">
    <v-row>
      <v-col cols="12" sm="4" md="3">
        <v-text-field
          :value="filterParams.search"
          :label="$t('search')"
          :error-messages="errors['search']"
          autocomplete="off"
          clearable
          prepend-icon="mdi-magnify"
          @input="debounceUpdateFilter('search', $event)"
          @click:clear="updateFilter('search', null)"
        />
      </v-col>

      <v-col cols="12" sm="4" md="3">
        <v-select
          :value="filterParams.product_ids"
          :label="$t('product_id')"
          :items="availableOptions.available_products"
          item-value="id"
          item-text="title"
          multiple
          prepend-icon="mdi-form-select"
          clearable
          @change="updateFilter('product_ids', $event)"
          @click:clear="updateFilter('product_ids', null)"
        />
      </v-col>

      <v-col cols="12" sm="4" md="3">
        <v-select
          :value="filterParams.statuses"
          :label="$t('status')"
          :items="availableOptions.available_statuses"
          item-value="id"
          item-text="title"
          multiple
          prepend-icon="mdi-form-select"
          clearable
          @change="updateFilter('statuses', $event)"
          @click:clear="updateFilter('statuses', null)"
        />
      </v-col>

      <v-col cols="12" sm="4" md="3">
        <v-select
          :value="filterParams.glass_ordered"
          :label="$t('glass_ordered')"
          :items="availableOptions.available_statuses_for_external_services"
          item-value="id"
          item-text="title"
          prepend-icon="mdi-form-select"
          clearable
          @change="updateFilter('glass_ordered', $event)"
          @click:clear="updateFilter('glass_ordered', null)"
        />
      </v-col>

      <v-col cols="12" sm="4" md="3">
        <v-select
          :value="filterParams.locks_ordered"
          :label="$t('locks_ordered')"
          :items="availableOptions.available_statuses_for_external_services"
          item-value="id"
          item-text="title"
          prepend-icon="mdi-form-select"
          clearable
          @change="updateFilter('locks_ordered', $event)"
          @click:clear="updateFilter('locks_ordered', null)"
        />
      </v-col>

      <v-col cols="12" sm="4" md="3">
        <v-select
          :value="filterParams.paint_ordered"
          :label="$t('paint_ordered')"
          :items="availableOptions.available_statuses_for_external_services"
          item-value="id"
          item-text="title"
          prepend-icon="mdi-form-select"
          clearable
          @change="updateFilter('paint_ordered', $event)"
          @click:clear="updateFilter('paint_ordered', null)"
        />
      </v-col>

      <v-col cols="12" sm="4" md="3" class="px-3">
        <BaseDatepickerInput
          :value="filterParams.confirmed_at_from"
          :label="$t('confirmed_at_from')"
          clearable
          @input="updateFilter('confirmed_at_from', $event)"
          @click:clear="updateFilter('confirmed_at_from', null)"
        />
      </v-col>
      <v-col cols="12" sm="4" md="3" class="px-3">
        <BaseDatepickerInput
          :value="filterParams.confirmed_at_to"
          :label="$t('confirmed_at_to')"
          clearable
          @input="updateFilter('confirmed_at_to', $event)"
          @click:clear="updateFilter('confirmed_at_to', null)"
        />
      </v-col>

      <v-col cols="12" sm="4" md="3" class="px-3">
        <BaseDatepickerInput
          :value="filterParams.deadline_at_from"
          :label="$t('deadline_at_from')"
          clearable
          @input="updateFilter('deadline_at_from', $event)"
          @click:clear="updateFilter('deadline_at_from', null)"
        />
      </v-col>
      <v-col cols="12" sm="4" md="3" class="px-3">
        <BaseDatepickerInput
          :value="filterParams.deadline_at_to"
          :label="$t('deadline_at_to')"
          clearable
          @input="updateFilter('deadline_at_to', $event)"
          @click:clear="updateFilter('deadline_at_to', null)"
        />
      </v-col>

      <v-col cols="12" sm="3">
        <v-select
          :value="filterParams.per_page"
          :label="$t('per_page')"
          :items="perPageOptions"
          item-value="id"
          item-text="text"
          prepend-icon="mdi-form-select"
          clearable
          @input="updateFilter('per_page', $event)"
          @click:clear="updateFilter('per_page', null)"
        />
      </v-col>

      <v-col cols="12" sm="4" md="2" class="d-flex align-center">
        <v-btn text @click="$emit('reset')">
          <v-icon left>mdi-close</v-icon>
          {{ $t('clear_all') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BaseDatepickerInput from '@/components/base/BaseDatepickerInput';

export default {
  name: 'OrdersFilter',

  components: { BaseDatepickerInput },

  props: {
    filterParams: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    availableOptions: {
      type: Object,
      required: true,
    },
  },

  computed: {
    perPageOptions() {
      return [
        {
          id: 50,
          text: '50',
        },
        {
          id: 75,
          text: '75',
        },
        {
          id: 100,
          text: '100',
        },
        {
          id: 150,
          text: '150',
        },
      ];
    },
  },

  methods: {
    updateFilter(key, value) {
      const params = { ...this.filterParams };
      if (value) {
        params[key] = value;
      } else {
        delete params[key];
      }
      this.$emit('update', params);
    },

    debounceUpdateFilter(key, value) {
      clearTimeout(this.timerId);
      this.timerId = setTimeout(() => {
        this.updateFilter(key, value);
      }, 600);
    },
  },
};
</script>

<style scoped></style>
