import { render, staticRenderFns } from "./BasePrimaryActionButton.vue?vue&type=template&id=3f80c362&scoped=true&"
import script from "./BasePrimaryActionButton.vue?vue&type=script&lang=js&"
export * from "./BasePrimaryActionButton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f80c362",
  null
  
)

export default component.exports